<template>
    <vx-card :title="'Complete Unloading Document'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="reqCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unlCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Request Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="requestDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="loadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unloadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Complete Putaway Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="29%">SKU Data</th>
                            <th width="6%">Qty</th>
                            <th width="7%">HU</th>
                            <th width="6%">Qty UOM</th>
                            <th width="7%">Status</th>
                            <th width="12%">Batch</th>
                            <th width="10%">Exp. Date</th>
                            <th width="23%">Storage Area</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(dataLine, index) in dataLines">
                            <!-- first row -->
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="dataLine.putaway_lines.length">
                                    <vs-input :value="dataLine.label" :readonly="true" class="w-full"></vs-input>
                                </td>

                                <!-- first -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].quantity" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].unit" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].quantity_uom" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].status" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].batch" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].exp_date" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="dataLine.putaway_lines[0].warehouse_area_code" :readonly="true" class="w-full"></vs-input>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(putaways, idx) in dataLines[index].putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + idx" v-if="idx > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.quantity" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.unit" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.quantity_uom" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.status" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.batch" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.exp_date" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.warehouse_area_code" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            action: null,
            reqCode: null,
            handoverCode: null,
            unlCode: null,
            selectedTerritory: null,
            selectedWarehouse: null,
            selectedSales: null,
            requestDate: null,
            loadingDate: null,
            unloadingDate: null,
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    label: null,
                    putaway_lines: [{
                        putaway_id:	0,
                        unit: null,
                        quantity: 0,
                        quantity_uom: 0,
                        status: null,
                        batch: null,
                        exp_date: null,
                        warehouse_area_code: null,
                    }]
                }
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/canvas/unloading/complete-putaway-data/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.reqCode = resp.data.request_code
                    this.handoverCode = resp.data.handover_code
                    this.unlCode = resp.data.unload_code
                    this.selectedTerritory = resp.data.territories.label
                    this.selectedWarehouse = resp.data.warehouses.label
                    this.selectedSales = resp.data.personals.label
                    this.requestDate = resp.data.request_date
                    this.loadingDate = resp.data.loading_date
                    this.unloadingDate = resp.data.unloading_date
                    this.dataLines = resp.data.sku_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.unloading",
                params: {status: 2}
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>